import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import GenericDoughnutChart from './GenericDoughnutChart';

const DoughnutChart = ({ claimed, unclaimed, escheated }) => {
  const labels = [
    ['Claimed'],
    ['Unclaimed'],
    // ['Dormant', '$' + escheated],
  ];
  const data = [+claimed, +unclaimed + +escheated];
  const backgroundColors = ['#36A2EB', '#BE3144', '#FFCE56'];

  const tooltipCallback = (tooltipItem) => {
    return `${tooltipItem.label}: ${tooltipItem.raw}`;
  };

  return (
    <GenericDoughnutChart
      labels={labels}
      data={data}
      backgroundColors={backgroundColors}
      tooltipCallback={tooltipCallback}
    />
  );
};

export default DoughnutChart;
