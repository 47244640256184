import { useCallback, useEffect, useMemo, useState } from 'react';
import { RefundsApi } from '../../clients';
import { debounce } from 'lodash';

export const useGetRefunds = ({ role }) => {
  const [refunds, setRefunds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilterType, setDateFilterType] = useState('created_at');
  const [facility, setFacility] = useState('');
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [subStatus, setSubStatus] = useState('');
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [offSet, setOffSet] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [payeeCategory, setPayeeCategory] = useState('');
  const [status, setStatus] = useState('');
  const [sortBy, setSortBy] = useState({ name: 'created_at', order: -1 });
  const [isError, setIsError] = useState(false);

  const fetchRefunds = async (filters, sort) => {
    setLoading(true);
    try {
      const { data } =
        role === 'admin'
          ? await RefundsApi.getRefundsForAdmin(filters, sort)
          : await RefundsApi.getRefundsForFacility(filters, sort);

      if (filters.offSet === 0) {
        setRefunds(data.refunds);
      } else {
        setRefunds((prev) => [...prev, ...data.refunds]);
      }
      setHasMore(data.refunds.length > 0);
    } catch (error) {
      console.error('Error fetching refunds', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = useCallback(
    debounce((searchValue) => {
      setDebouncedSearch(searchValue);
    }, 1000),
    [setDebouncedSearch, debouncedSearch],
  );

  useEffect(() => {
    const filters = {
      payeeCategory,
      status,
      startDate,
      dateFilterType,
      endDate,
      facility,
      search,
      paymentMethod,
      subStatus,
      selectedBusinessUnit,
      transactionStatus,
      additionalInfo,
      offSet,
      isError,
    };
    const sort = {
      sortBy,
    };
    fetchRefunds(filters, sort).catch((error) =>
      console.error('Error fetching refunds', error),
    );
  }, [
    payeeCategory,
    status,
    startDate,
    dateFilterType,
    endDate,
    facility,
    debouncedSearch,
    paymentMethod,
    subStatus,
    selectedBusinessUnit,
    transactionStatus,
    additionalInfo,
    offSet,
    sortBy.name,
    sortBy.order,
    isError,
  ]);

  // need to reset the offset if any of the filters are changing
  useEffect(() => {
    setOffSet(0);
  }, [
    payeeCategory,
    status,
    startDate,
    dateFilterType,
    endDate,
    facility,
    debouncedSearch,
    paymentMethod,
    subStatus,
    selectedBusinessUnit,
    transactionStatus,
    additionalInfo,
    sortBy.name,
    sortBy.order,
    isError,
  ]);

  const refetchRefunds = useCallback(() => {
    setOffSet(0);
    const filters = {
      payeeCategory,
      status,
      startDate,
      dateFilterType,
      endDate,
      facility,
      search,
      paymentMethod,
      subStatus,
      selectedBusinessUnit,
      transactionStatus,
      additionalInfo,
      offSet: 0,
      isError,
    };
    const sort = {
      sortBy,
    };
    fetchRefunds(filters, sort).catch((error) =>
      console.error('Error fetching refunds', error),
    );
  }, [
    payeeCategory,
    status,
    startDate,
    dateFilterType,
    endDate,
    facility,
    debouncedSearch,
    paymentMethod,
    subStatus,
    selectedBusinessUnit,
    transactionStatus,
    additionalInfo,
    offSet,
    sortBy.name,
    sortBy.order,
    isError,
  ]);

  const payments = useMemo(() => {
    return refunds.map((payment) => {
      payment.isChecked = false;
      payment.refund_amount = +payment.refund_amount;
      payment.refund_method_string =
        payment.refund_method == 2
          ? 'Paypal'
          : payment.refund_method == 3
          ? 'Venmo'
          : payment.refund_method == 4
          ? 'Gift Card'
          : payment.refund_method == 5
          ? 'Check'
          : payment.refund_method == 6
          ? 'Donations'
          : payment.refund_method == 7
          ? 'ACH'
          : payment.refund_method == 0
          ? 'Escheated'
          : '';
      payment.refund_status = payment.refund_status;
      return payment;
    });
  }, [refunds]);

  return {
    refunds,
    payments,

    loading,
    setLoading,

    payeeCategory,
    setPayeeCategory,

    status,
    setStatus,

    startDate,
    setStartDate,

    dateFilterType,
    setDateFilterType,

    endDate,
    setEndDate,

    facility,
    setFacility,

    search,
    setSearch,
    handleSearch,

    paymentMethod,
    setPaymentMethod,

    subStatus,
    setSubStatus,

    selectedBusinessUnit,
    setSelectedBusinessUnit,

    transactionStatus,
    setTransactionStatus,

    additionalInfo,
    setAdditionalInfo,

    offSet,
    setOffSet,

    hasMore,

    sortBy,
    setSortBy,

    isError,
    setIsError,

    refetchRefunds
  };
};
