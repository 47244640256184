import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Delete } from '@material-ui/icons';
import { REFUND_TYPES } from '../../../utility';
import { InputLabel, Select } from '@material-ui/core';

export default function CreateYourMapping({
  formValues,
  setFormValues,
  setUpdatingBlocks,
  updatingBlocks,
  editId,
  isUpdating,
  onDataMappingCancel,
  updateDataMapping,
  allowedRefundTypes,
  regularInputList,
  insuranceInputList,
  addSplittingValue,
  onSplittingValueChange,
  removeSplittingValue,
  copyDataMappingFrom,
  onExceptionChange,
  removeException,
}) {
  const [consumerExpanded, setConsumerExpanded] = useState(false);
  const [insuranceExpanded, setInsuranceExpanded] = useState(false);
  const [operation, setOperation] = useState('');

  useEffect(() => {
    if (formValues.insurance_data_mapping?.exceptions?.length) {
      setOperation(formValues.insurance_data_mapping.exceptions[0].operation);
    } else {
      setOperation('=');
    }
  }, [formValues.insurance_data_mapping?.exceptions]);

  const handleRefundTypeChange = useCallback(
    (e, type) => {
      const newRefundTypes = e.target.checked
        ? [...formValues.refund_types, type]
        : formValues.refund_types.filter((refundType) => refundType !== type);
      setFormValues({
        ...formValues,
        refund_types: newRefundTypes,
      });
    },
    [formValues, setFormValues],
  );

  const consumerPaymentsEnabled = useMemo(() => {
    if (allowedRefundTypes.includes(REFUND_TYPES.REGULAR)) {
      return formValues.refund_types.includes(REFUND_TYPES.REGULAR);
    }
    return false;
  }, [allowedRefundTypes, formValues.refund_types]);
  const insurancePaymentsEnabled = useMemo(() => {
    if (allowedRefundTypes.includes(REFUND_TYPES.INSURANCE)) {
      return formValues.refund_types.includes(REFUND_TYPES.INSURANCE);
    }
    return false;
  }, [allowedRefundTypes, formValues.refund_types]);
  const allEnabled = useMemo(() => {
    return allowedRefundTypes.length > 1;
  }, [allowedRefundTypes]);

  useEffect(() => {
    if (!allEnabled) {
      if (consumerPaymentsEnabled) {
        setConsumerExpanded(true);
      }
      if (insurancePaymentsEnabled) {
        setInsuranceExpanded(true);
      }
    }
  }, [allEnabled, consumerPaymentsEnabled, insurancePaymentsEnabled]);

  const handleOnExpanded = useCallback(
    (type) => {
      return function () {
        if (type === REFUND_TYPES.REGULAR) {
          setConsumerExpanded((prev) => !prev);
        }
        if (type === REFUND_TYPES.INSURANCE) {
          setInsuranceExpanded((prev) => !prev);
        }
      };
    },
    [allEnabled],
  );

  const handleOperationChange = (e) => {
    const newOperation = e.target.value;
    setOperation(newOperation);
    setFormValues((values) => {
      const updatedExceptions = values.insurance_data_mapping.exceptions?.map(
        (exception) => ({
          ...exception,
          operation: newOperation,
        }),
      );
      return {
        ...values,
        insurance_data_mapping: {
          ...values.insurance_data_mapping,
          exceptions: updatedExceptions,
        },
      };
    });
  };

  const addException = () => {
    setFormValues((values) => {
      return {
        ...values,
        insurance_data_mapping: {
          ...values.insurance_data_mapping,
          exceptions: [
            {
              subStatus: 'Payee Exception',
              columnName: 'business_payee_name',
              contains: [
                ...(values.insurance_data_mapping?.exceptions?.at(0)
                  ?.contains ?? []),
                '',
              ],
              operation: operation,
            },
          ],
        },
      };
    });
  };

  return (
    <Card className='dashboardCard table_card_height'>
      <CardBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3 className='subHeading'>Create your mapping</h3>
            <h2 style={{ fontSize: '17px' }} className='page-heading'>
              In the email / letter templates, please use the values in "
              {'{{}}'}" including the double curly brackets to populate fields:
            </h2>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className='custom-select'>
                  {allEnabled && (
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'>
                        Payee category types In File
                      </FormLabel>
                      <FormGroup row>
                        {(allEnabled || consumerPaymentsEnabled) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formValues.refund_types.includes(
                                  REFUND_TYPES.REGULAR,
                                )}
                                onChange={(e) =>
                                  handleRefundTypeChange(
                                    e,
                                    REFUND_TYPES.REGULAR,
                                  )
                                }
                                name={REFUND_TYPES.REGULAR}
                                disabled={
                                  !isUpdating('data-mapping') ||
                                  !allowedRefundTypes.includes(
                                    REFUND_TYPES.REGULAR,
                                  )
                                }
                              />
                            }
                            label='Consumer Payments'
                          />
                        )}
                        {(allEnabled || insurancePaymentsEnabled) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formValues.refund_types.includes(
                                  REFUND_TYPES.INSURANCE,
                                )}
                                onChange={(e) =>
                                  handleRefundTypeChange(
                                    e,
                                    REFUND_TYPES.INSURANCE,
                                  )
                                }
                                name={REFUND_TYPES.INSURANCE}
                                disabled={
                                  !isUpdating('data-mapping') ||
                                  !allowedRefundTypes.includes(
                                    REFUND_TYPES.INSURANCE,
                                  )
                                }
                              />
                            }
                            label='Insurance Payments'
                          />
                        )}
                      </FormGroup>
                    </FormControl>
                  )}
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}></GridItem>

              {consumerPaymentsEnabled && (
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    {formValues.regular_splitting_value.map(
                      (splittingValue, index) => (
                        <GridItem xs={12} sm={12} md={12} key={index}>
                          <CustomInput
                            type='text'
                            labelText={`Consumer Payment Filter`}
                            onChange={(e) =>
                              onSplittingValueChange(
                                e,
                                index,
                                REFUND_TYPES.REGULAR,
                              )
                            }
                            value={splittingValue}
                            name='splittingValue'
                            formControlProps={{}}
                            disabled={!isUpdating('data-mapping')}
                          />
                          <Delete
                            onClick={() =>
                              removeSplittingValue(index, REFUND_TYPES.REGULAR)
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        </GridItem>
                      ),
                    )}
                  </GridContainer>
                  <div xs={12} sm={12} md={6} style={{ padding: 0 }}>
                    <Button
                      color='primary'
                      disabled={!isUpdating('data-mapping')}
                      onClick={() => addSplittingValue(REFUND_TYPES.REGULAR)}>
                      Add Consumer Payment Filter
                    </Button>
                  </div>
                </GridItem>
              )}

              {insurancePaymentsEnabled && (
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    {formValues.insurance_splitting_value.map(
                      (splittingValue, index) => (
                        <GridItem xs={12} sm={12} md={12} key={index}>
                          <CustomInput
                            type='text'
                            labelText={`Insurance Filter`}
                            onChange={(e) =>
                              onSplittingValueChange(
                                e,
                                index,
                                REFUND_TYPES.INSURANCE,
                              )
                            }
                            value={splittingValue}
                            name='splittingValue'
                            formControlProps={{}}
                            disabled={!isUpdating('data-mapping')}
                          />
                          <Delete
                            onClick={() =>
                              removeSplittingValue(
                                index,
                                REFUND_TYPES.INSURANCE,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        </GridItem>
                      ),
                    )}
                  </GridContainer>
                  <div xs={12} sm={12} md={6} style={{ padding: 0 }}>
                    <Button
                      color='primary'
                      disabled={!isUpdating('data-mapping')}
                      onClick={() => addSplittingValue(REFUND_TYPES.INSURANCE)}>
                      Add Insurance Filter
                    </Button>
                  </div>
                </GridItem>
              )}
            </GridContainer>

            {consumerPaymentsEnabled && (
              <Accordion
                expanded={consumerExpanded}
                style={{ marginTop: '20px' }}
                key={'Consumer Payments'}
                defaultExpanded={false}
                disabled={!isUpdating('data-mapping')}
                onChange={handleOnExpanded(REFUND_TYPES.REGULAR)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2-content'
                  id='panel2-header'>
                  <Typography>{'Consumer Payments'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card>
                    <CardBody>
                      {insurancePaymentsEnabled && (
                        <Button
                          color='primary'
                          disabled={!isUpdating('data-mapping')}
                          onClick={() =>
                            copyDataMappingFrom(REFUND_TYPES.INSURANCE)
                          }>
                          Copy From Below
                        </Button>
                      )}
                      <GridContainer
                        style={{ justifyContent: 'space-between' }}>
                        {regularInputList}
                      </GridContainer>
                    </CardBody>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}
            {insurancePaymentsEnabled && (
              <Accordion
                expanded={insuranceExpanded}
                key={REFUND_TYPES.INSURANCE}
                defaultExpanded={false}
                disabled={!isUpdating('data-mapping')}
                onChange={handleOnExpanded(REFUND_TYPES.INSURANCE)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel2-content'
                  id='panel2-header'>
                  <Typography>{'Insurance'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Card>
                    <CardBody>
                      {consumerPaymentsEnabled && (
                        <Button
                          color='primary'
                          disabled={!isUpdating('data-mapping')}
                          onClick={() =>
                            copyDataMappingFrom(REFUND_TYPES.REGULAR)
                          }>
                          Copy From Above
                        </Button>
                      )}
                      <GridContainer
                        style={{ justifyContent: 'space-between' }}>
                        {insuranceInputList}
                      </GridContainer>
                      <GridItem xs={6} sm={6} md={6}>
                        <div className='custom-select'>
                          <FormControl>
                            <InputLabel id='operation'>
                              Business Payee Name Matching
                            </InputLabel>
                            <Select
                              labelId='operation'
                              value={operation}
                              onChange={handleOperationChange}
                              name='operation'
                              className='full-width-elem'>
                              <MenuItem value='='>Business Payee Name Below Are Exceptions</MenuItem>
                              <MenuItem value='!='>Business Payee Name Below Are Not Exceptions</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          {formValues.insurance_data_mapping?.exceptions
                            ?.at(0)
                            ?.contains?.map((exceptionValue, index) => (
                              <GridItem xs={6} sm={6} md={6} key={index}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <div
                                    style={{
                                      width: '80%',
                                    }}>
                                    <CustomInput
                                      type='text'
                                      labelText={`Business Payee Name`}
                                      onChange={(e) =>
                                        onExceptionChange(e, index)
                                      }
                                      value={exceptionValue}
                                      name='exceptionValue'
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      disabled={!isUpdating('data-mapping')}
                                    />
                                  </div>
                                  <Delete
                                    onClick={() => removeException(index)}
                                    style={{
                                      cursor: 'pointer',
                                      marginTop: '30px',
                                    }}
                                  />
                                </div>
                              </GridItem>
                            ))}
                        </GridContainer>
                        <div xs={12} sm={12} md={6} style={{ padding: 0 }}>
                          <Button
                            color='primary'
                            disabled={!isUpdating('data-mapping')}
                            onClick={addException}>
                            Add Business Payee Name
                          </Button>
                        </div>
                      </GridItem>
                    </CardBody>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}
          </GridItem>
        </GridContainer>
        {editId && !updatingBlocks.includes('data-mapping') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={() =>
                setUpdatingBlocks([...updatingBlocks, 'data-mapping'])
              }
              color='primary'>
              Edit
            </Button>
          </div>
        )}

        {updatingBlocks.includes('data-mapping') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={onDataMappingCancel}
              color='danger'>
              Cancel
            </Button>
            <Button
              style={{ marginTop: '10px', marginBottom: '-10px' }}
              onClick={updateDataMapping}
              color='primary'>
              Save Data Mapping
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
