import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const GenericDoughnutChart = ({
  labels,
  data,
  backgroundColors,
  tooltipCallback,
}) => {
  return (
    <Doughnut
      plugins={[
        ChartDataLabels,
        {
          id: 'legendMargin',
          beforeInit: function (chart) {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
              fitValue.bind(chart.legend)();
              return (this.height += 40);
            };
          },
        },
      ]}
      options={{
        layout: {
          padding: {
            bottom: 20,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: tooltipCallback,
            },
          },
          datalabels: {
            formatter: function () {
              return '';
            },
            anchor: 'center',
            align: 'right',
            clamp: false,
            color: '#ffffff',
            font: {
              weight: 'bold',
              size: 12,
            },
          },
          legend: {
            display: true,
            labels: {
              padding: 10,
              boxWidth: 10,
              boxHeight: 10,
              boxPadding: 0,
            },
            align: 'center',
            fullSize: true,
            position: 'left',
            textDirection: 'rtl',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
          x: {
            display: false,
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderWidth: 0,
          },
        ],
      }}
    />
  );
};

export default GenericDoughnutChart;
