import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { API_BASE_URL } from 'config';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { TextField } from '@mui/material';

import { useHistory, useParams } from 'react-router-dom';
import FileConfiguration from './FileConfiguration';
import SftpPickUp from './SftpPickUp';
import OutboundReporting from './OutboundReporting';
import CreateYourMapping from './CreateYourMapping';

const labelStyle = {
  fontSize: '12px',
  color: 'grey',
  cursor: 'pointer',
};

export default function CreateOrEditDataMappings() {
  const [updatingBlocks, setUpdatingBlocks] = useState([]);
  const [formValues, setFormValues] = useState({
    name: '',
    delimiter: '',
    contains_header: '',
    rows_to_skip: '0',
    refund_types: '',
    insurance_splitting_value: [],
    regular_splitting_value: [],
    file_source: '',
    file_destination: '',
    sftp_file_extension: '',
    is_encrypted: '',
    is_sftp_enabled: false,
    outbound_reporting: false,
    outbound_file_location: '',
    outbound_file_extension: '',
    encrypt_outbound: '',
    include_headers_outbound: '',
    reporting_columns: [],
    outbound_file_delimiter: '',
    outbound_file_name: '',
    outbound_file_date_format: '',
  });

  const [originalFormValues, setOriginalFormValues] = useState({});
  const [allowedRefundTypes, setAllowedRefundTypes] = useState([]);
  const history = useHistory();
  const [availableColumns, setAvailableColumns] = useState([]);
  const classes = useStyles();
  const regularInputList = [];
  const insuranceInputList = [];
  const { id: editId } = useParams();

  const confirmChanges = (scope) => {
    return confirm(`Are you sure you want to save ${scope}?`);
  };

  const addSplittingValue = (type) => {
    const splittingType =
      type === 'regular'
        ? 'regular_splitting_value'
        : 'insurance_splitting_value';
    setFormValues((values) => {
      return {
        ...values,
        [splittingType]: [...values[splittingType], ''],
      };
    });
  };

  const onSplittingValueChange = (e, index, type) => {
    const splittingType =
      type === 'regular'
        ? 'regular_splitting_value'
        : 'insurance_splitting_value';
    const newSplittingValues = formValues[
      splittingType
    ].map((splittingValue, i) =>
      i === index ? e.target.value : splittingValue,
    );
    setFormValues({
      ...formValues,
      [splittingType]: newSplittingValues,
    });
    ``;
  };

  const getAvailableColumns = async () => {
    try {
      const response = await axios.get(
        API_BASE_URL + '/data-mappings/columns-for-reporting',
      );
      setAvailableColumns(response.data);
    } catch (err) {}
  };

  useEffect(() => {
    getAvailableColumns();
  }, []);

  const removeSplittingValue = (index, type) => {
    const splittingType =
      type === 'regular'
        ? 'regular_splitting_value'
        : 'insurance_splitting_value';
    const newSplittingValues = formValues[splittingType].filter(
      (_, i) => i !== index,
    );
    setFormValues({
      ...formValues,
      [splittingType]: newSplittingValues,
    });
  };

  const onInputChange = (e, type) => {
    setFormValues((values) => {
      const dataMappingType =
        type === 'regular' ? 'regular_data_mapping' : 'insurance_data_mapping';

      return {
        ...values,

        [dataMappingType]: {
          ...values[dataMappingType],
          structure: {
            ...values[dataMappingType].structure,
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  const getDefaultDataMappingValues = async () => {
    try {
      if (editId) return;
      const response = await axios.get(
        API_BASE_URL + '/data-mappings/default-data-mapping',
      );
      setFormValues((values) => ({
        ...values,
        regular_data_mapping: { structure: { ...response.data } },
        insurance_data_mapping: { structure: { ...response.data } },
      }));
    } catch (err) {}
  };

  const getDataMapping = async (id) => {
    try {
      const response = await axios.get(
        API_BASE_URL + '/data-mappings/get-data-mapping-by-id/' + id,
      );

      const formValues = {
        ...response.data,
        is_encrypted: response.data.is_encrypted.toString(),
        is_sftp_enabled: response.data.is_sftp_enabled,
        contains_header: response.data.contains_header.toString(),
        refund_types: response.data.refund_types ?? [],
        reporting_columns: response.data.reporting_columns || [],
        encrypt_outbound: response.data.encrypt_outbound.toString(),
        include_headers_outbound: response.data.include_headers_outbound.toString(),
      };

      setFormValues({
        ...formValues,
      });

      setOriginalFormValues({ ...formValues });
    } catch (err) {}
  };

  const getAllowedRefundTypes = async () => {
    try {
      const response = await axios.get(API_BASE_URL + '/allowed_refund_types');
      setAllowedRefundTypes(response.data.allowed_refund_types);

      if (response.data.allowed_refund_types?.length === 1 && !editId) {
        setFormValues((prev) => ({
          ...prev,
          refund_types: response.data.allowed_refund_types,
        }));
      }
    } catch (err) {}
  };

  useEffect(async () => {
    if (editId) {
      await getDataMapping(editId);
    }
    getDefaultDataMappingValues();
    getAllowedRefundTypes();
  }, [editId]);

  const handleInputChange = (e) => {
    setFormValues((fs) => {
      return { ...fs, [e.target.name]: e.target.value };
    });
  };

  const createDataMapping = async () => {
    if (!formValues.refund_types?.length) {
      toast.error('You need to select at least one payee category type.');
      return;
    }
    try {
      await axios.post(
        API_BASE_URL + '/data-mappings/create-data-mapping-group',
        {
          ...formValues,
        },
      );
      history.push('/facility/manage-data-mapping');
    } catch (err) {}
  };

  const updateSftpConfiguration = async () => {
    try {
      if (!editId) return;
      if (!confirmChanges('SFTP Pickup Configuration')) return;

      await axios.put(
        API_BASE_URL +
          '/data-mappings/' +
          '/update-sftp-configuration/' +
          editId,
        {
          is_sftp_enabled: formValues.is_sftp_enabled,
          file_source: formValues.file_source,
          file_destination: formValues.file_destination,
          sftp_file_extension: formValues.sftp_file_extension,
          is_encrypted: formValues.is_encrypted,
        },
      );
      setUpdatingBlocks((updates) => {
        return updates.filter((block) => block !== 'sftp-configuration');
      });
    } catch (err) {}
  };

  const updateFileConfiguration = async () => {
    try {
      if (!editId) return;
      if (!confirmChanges('File Configuration')) return;

      await axios.put(
        API_BASE_URL +
          '/data-mappings/' +
          '/update-file-configuration/' +
          editId,
        {
          name: formValues.name,
          delimiter: formValues.delimiter,
          contains_header: formValues.contains_header,
          rows_to_skip: formValues.rows_to_skip,
        },
      );
      setUpdatingBlocks((updates) => {
        return updates.filter((block) => block !== 'file-configuration');
      });
    } catch (err) {}
  };

  const onFileConfigurationCancel = () => {
    setUpdatingBlocks((updates) => {
      return updates.filter((block) => block !== 'file-configuration');
    });

    setFormValues((values) => {
      return {
        ...values,
        name: originalFormValues.name,
        delimiter: originalFormValues.delimiter,
        contains_header: originalFormValues.contains_header,
        rows_to_skip: originalFormValues.rows_to_skip,
      };
    });
  };

  const onSftpConfigurationCancel = () => {
    setUpdatingBlocks((updates) => {
      return updates.filter((block) => block !== 'sftp-configuration');
    });

    setFormValues((values) => {
      return {
        ...values,
        is_sftp_enabled: originalFormValues.is_sftp_enabled,
        file_source: originalFormValues.file_source,
        file_destination: originalFormValues.file_destination,
        sftp_file_extension: originalFormValues.sftp_file_extension,
        is_encrypted: originalFormValues.is_encrypted,
      };
    });
  };

  const onDataMappingCancel = () => {
    setUpdatingBlocks((updates) => {
      return updates.filter((block) => block !== 'data-mapping');
    });

    setFormValues((values) => {
      return {
        ...values,
        refund_types: originalFormValues.refund_types,
        insurance_splitting_value: originalFormValues.insurance_splitting_value,
        regular_splitting_value: originalFormValues.regular_splitting_value,
        regular_data_mapping: { ...originalFormValues.regular_data_mapping },
        insurance_data_mapping: {
          ...originalFormValues.insurance_data_mapping,
        },
      };
    });
  };

  const updateDataMapping = async () => {
    try {
      if (!editId) return;
      if (!confirmChanges('Data Mapping')) return;

      await axios.put(
        API_BASE_URL + '/data-mappings/update-data-mapping/' + editId,
        {
          refund_types: formValues.refund_types,
          insurance_splitting_value: formValues.insurance_splitting_value,
          regular_splitting_value: formValues.regular_splitting_value,
          regular_data_mapping: formValues.regular_data_mapping,
          insurance_data_mapping: {
            ...formValues.insurance_data_mapping,
            exceptions: formValues.insurance_data_mapping?.exceptions?.map(
              (exception) => {
                exception.contains = exception.contains.filter(
                  (value) => value !== '',
                );
                return exception;
              },
            ),
          },
        },
      );
      setUpdatingBlocks((updates) => {
        return updates.filter((block) => block !== 'data-mapping');
      });
      if (editId) {
        await getDataMapping(editId);
      }
    } catch (err) {}
  };

  const updateOutboundReporting = async () => {
    try {
      if (!editId) return;
      if (!confirmChanges('Outbound Reporting')) return;

      await axios.put(
        API_BASE_URL +
          '/data-mappings/' +
          '/update-outbound-reporting/' +
          editId,
        {
          outbound_reporting: formValues.outbound_reporting,
          outbound_file_location: formValues.outbound_file_location,
          outbound_file_extension: formValues.outbound_file_extension,
          encrypt_outbound: formValues.encrypt_outbound,
          include_headers_outbound: formValues.include_headers_outbound,
          reporting_columns: formValues.reporting_columns,
          outbound_file_delimiter: formValues.outbound_file_delimiter,
          outbound_file_name: formValues.outbound_file_name,
          outbound_file_date_format: formValues.outbound_file_date_format,
        },
      );
      setUpdatingBlocks((updates) => {
        return updates.filter((block) => block !== 'outbound-reporting');
      });
    } catch (err) {}
  };

  const onOutboundReportingCancel = () => {
    setUpdatingBlocks((updates) => {
      return updates.filter((block) => block !== 'outbound-reporting');
    });

    setFormValues((values) => {
      return {
        ...values,
        outbound_reporting: originalFormValues.outbound_reporting,
        outbound_file_location: originalFormValues.outbound_file_location,
        outbound_file_extension: originalFormValues.outbound_file_extension,
        encrypt_outbound: originalFormValues.encrypt_outbound,
        include_headers_outbound: originalFormValues.include_headers_outbound,
        reporting_columns: originalFormValues.reporting_columns,
        outbound_file_delimiter: originalFormValues.outbound_file_delimiter,
        outbound_file_name: originalFormValues.outbound_file_name,
        outbound_file_date_format: originalFormValues.outbound_file_date_format,
      };
    });
  };

  const addReportingColumn = () => {
    setFormValues((values) => ({
      ...values,
      reporting_columns: [
        ...values.reporting_columns,
        {
          column_name: values.column_name,
          variable_name: values.variable_name,
          format: values.formats,
        },
      ],
      column_name: '',
      variable_name: '',
      format: '',
    }));
  };

  const updateReportingColumn = (index, key, value) => {
    const updatedColumns = formValues.reporting_columns.map((column, i) =>
      i === index ? { ...column, [key]: value } : column,
    );
    setFormValues({
      ...formValues,
      reporting_columns: updatedColumns,
    });
  };

  const removeReportingColumn = (index) => {
    const updatedColumns = formValues.reporting_columns.filter(
      (_, i) => i !== index,
    );
    setFormValues({
      ...formValues,
      reporting_columns: updatedColumns,
    });
  };

  const isUpdating = (block) => updatingBlocks.includes(block) || !editId;

  const copyDataMappingFrom = (typeFrom) => {
    const dataMappingType =
      typeFrom === 'regular'
        ? 'insurance_data_mapping'
        : 'regular_data_mapping';
    const otherDataMappingType =
      typeFrom === 'regular'
        ? 'regular_data_mapping'
        : 'insurance_data_mapping';

    setFormValues((values) => {
      return {
        ...values,
        [dataMappingType]: {
          structure: { ...values[otherDataMappingType]?.structure },
        },
      };
    });
  };

  const generateInput = (key, type) => {
    return (
      <GridItem key={key} style={{ margin: '10px', minWidth: '30%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <div
            onClick={() => {
              navigator.clipboard.writeText(`{{${key}}}`);
              toast.success('Copied to clipboard', { autoClose: 1000 });
            }}
            style={{ display: 'flex', alignItems: 'center' }}>
            <label style={labelStyle}>
              {' '}
              {key} - {'{{'}
              {key}
              {'}}'}
            </label>{' '}
            <AssignmentOutlinedIcon
              style={{ color: '#3AB795', fontSize: '20px', cursor: 'copy' }}
            />
          </div>

          <TextField
            fullWidth={true}
            disabled={!isUpdating('data-mapping')}
            key={key}
            variant='outlined'
            color='primary'
            className={classes.root}
            placeholder={key}
            multiline
            inputProps={{
              style: {
                outlineColor: '#00bcd4',
                borderColor: '#00bcd4',
                fontSize: '14px',
                lineHeight: '1',
                width: '300px',
              },
            }}
            minRows={1}
            maxRows={1}
            value={
              formValues?.[
                type === 'regular'
                  ? 'regular_data_mapping'
                  : 'insurance_data_mapping'
              ]?.structure?.[key]
            }
            onChange={(e) => onInputChange(e, type)}
            name={key}
          />
        </div>
      </GridItem>
    );
  };

  const onExceptionChange = (e, index) => {
    const exceptionValues = (
      formValues.insurance_data_mapping.exceptions?.at(0)?.contains ?? []
    ).map((exceptionValue, i) =>
      i === index ? e.target.value : exceptionValue,
    );
    setFormValues({
      ...formValues,
      insurance_data_mapping: {
        ...formValues.insurance_data_mapping,
        exceptions: [
          {
            ...formValues.insurance_data_mapping.exceptions?.at(0),
            contains: exceptionValues,
          },
        ],
      },
    });
  };

  const removeException = (index) => {
    const exceptions = formValues.insurance_data_mapping.exceptions
      ?.at(0)
      ?.contains.filter((_, i) => i !== index);
    setFormValues({
      ...formValues,
      insurance_data_mapping: {
        ...formValues.insurance_data_mapping,
        exceptions: [
          {
            ...formValues.insurance_data_mapping.exceptions?.at(0),
            contains: exceptions,
          },
        ],
      },
    });
  };

  if (formValues?.regular_data_mapping) {
    for (const key of Object.keys(formValues.regular_data_mapping.structure)) {
      regularInputList.push(generateInput(key, 'regular'));

      insuranceInputList.push(generateInput(key, 'insurance'));
    }
  }

  return (
    <div style={{ marginTop: '-40px' }}>
      <FileConfiguration
        formValues={formValues}
        setFormValues={setFormValues}
        originalFormValues={originalFormValues}
        setUpdatingBlocks={setUpdatingBlocks}
        updatingBlocks={updatingBlocks}
        editId={editId}
        isUpdating={isUpdating}
        onFileConfigurationCancel={onFileConfigurationCancel}
        updateFileConfiguration={updateFileConfiguration}
        handleInputChange={handleInputChange}
      />
      <SftpPickUp
        formValues={formValues}
        setFormValues={setFormValues}
        originalFormValues={originalFormValues}
        setUpdatingBlocks={setUpdatingBlocks}
        updatingBlocks={updatingBlocks}
        editId={editId}
        isUpdating={isUpdating}
        onSftpConfigurationCancel={onSftpConfigurationCancel}
        updateSftpConfiguration={updateSftpConfiguration}
        handleInputChange={handleInputChange}
      />

      <CreateYourMapping
        formValues={formValues}
        setFormValues={setFormValues}
        originalFormValues={originalFormValues}
        setUpdatingBlocks={setUpdatingBlocks}
        updatingBlocks={updatingBlocks}
        editId={editId}
        isUpdating={isUpdating}
        onDataMappingCancel={onDataMappingCancel}
        updateDataMapping={updateDataMapping}
        allowedRefundTypes={allowedRefundTypes}
        regularInputList={regularInputList}
        insuranceInputList={insuranceInputList}
        addSplittingValue={addSplittingValue}
        onSplittingValueChange={onSplittingValueChange}
        removeSplittingValue={removeSplittingValue}
        copyDataMappingFrom={copyDataMappingFrom}
        onExceptionChange={onExceptionChange}
        removeException={removeException}
      />

      <OutboundReporting
        formValues={formValues}
        setFormValues={setFormValues}
        originalFormValues={originalFormValues}
        setUpdatingBlocks={setUpdatingBlocks}
        updatingBlocks={updatingBlocks}
        editId={editId}
        isUpdating={isUpdating}
        onOutboundReportingCancel={onOutboundReportingCancel}
        updateOutboundReporting={updateOutboundReporting}
        availableColumns={availableColumns}
        addReportingColumn={addReportingColumn}
        updateReportingColumn={updateReportingColumn}
        removeReportingColumn={removeReportingColumn}
        handleInputChange={handleInputChange}
      />
      {!editId && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '20px',
          }}>
          <Button
            style={{ margin: '10px' }}
            onClick={createDataMapping}
            color='primary'>
            Save Mapping
          </Button>
        </div>
      )}
    </div>
  );
}
