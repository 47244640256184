import axios from 'axios';
import { API_BASE_URL } from '../../../config';

export class RefundsApi {
  static getRefundsForAdmin = (filters, sort) => {
    return axios.post(
      API_BASE_URL + '/refundlist_paginated_admin',
      RefundsApi.handleSortAndFilters(filters, sort),
    );
  };

  static getRefundsForFacility = (filters, sort) => {
    return axios.post(
      API_BASE_URL + '/refundlist_paginated',
      RefundsApi.handleSortAndFilters(filters, sort),
    );
  };

  static handleSortAndFilters = (filters, sort) => {
    return {
      // filters
      refundType: filters.payeeCategory,
      refundStatus: filters.status || undefined,
      startDate: filters.startDate,
      dateFilterType: filters.dateFilterType,
      endDate: filters.endDate,
      facility_id: filters.facility,
      search: filters.search,
      refundMethod: filters.paymentMethod || undefined,
      subStatus: filters.subStatus,
      business_unit_id: filters.selectedBusinessUnit,
      transactionStatus: filters.transactionStatus,
      additionalInfo: filters.additionalInfo,

      // pagination
      limit: filters.limit ?? 25,
      offset: filters.offSet,

      // sort
      orderByColumn: sort.sortBy.name,
      orderType: sort.sortBy.order == 1 ? 'ASC' : 'DESC',

      isError: filters.isError,
    };
  };
}
