import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import Card from 'components/Card/Card.js';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'components/CustomButtons/Button.js';

import search from '../../assets/img/search.svg';
import GenericDoughnutChart from './GenericDoughnutChart';

import Icon from '@material-ui/core/Icon';

import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardFooter from 'components/Card/CardFooter.js';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import { API_BASE_URL } from 'config';
import { CSVLink } from 'react-csv';

const postageCodes = {
  '1': 'USPS First Class',
  '2': 'USPS Priority',
  '3': 'USPS Priority + Delivery Confirmation',
  '5': 'FedEx Express 2 Day',
  '6': 'FedEx Express Overnight',
  '8': 'USPS Overnight',
  '9': 'USPS Certified Mail',
  '10': 'FedEx Priority Overnight',
};
const defaultPostageData = {
  insurance: {
    '1': 0,
    '2': 0,
    '3': 0,
    '5': 0,
    '6': 0,
    '8': 0,
    '9': 0,
    '10': 0,
  },
  regular: {
    '1': 0,
    '2': 0,
    '3': 0,
    '5': 0,
    '6': 0,
    '8': 0,
    '9': 0,
    '10': 0,
  },
};

const defaultEnvelopeData = {
  '#10 Envelope USPS First Class': 0,
  '6x9 Envelope USPS First Class': 0,
  '9x12 Flat Envelope USPS First Class': 0,
  'FedEx Express/USPS Priority Flat Rate Envelope': 0,
  'USPS Priority Medium Box': 0,
};
const useStyles = makeStyles(styles);

function PostageAccounting({ facility }) {
  const classes = useStyles();

  const [dates, setDates] = useState({
    startDate: moment().subtract(1, 'months').toDate(),
    endDate: moment().toDate(),
  });
  const [facilityId, setFacilityId] = useState(null);
  const [pdfPageCount, setPdfPageCount] = useState(0);
  const [checkCount, setCheckCount] = useState(0);

  const [postageCounts, setPostageCounts] = useState(defaultPostageData);
  const [envelopeCount, setEnvelopeCount] = useState(0);

  const [envelopeData, setEnvelopeData] = useState({
    insurance: defaultEnvelopeData,
    regular: defaultEnvelopeData,
  });

  const [chartType, setChartType] = useState('insurance');
  const [envelopeChartType, setEnvelopeChartType] = useState('insurance');

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  const handleEnvelopeChartTypeChange = (e) => {
    setEnvelopeChartType(e.target.value);
  };

  const postageLabels = Object.values(postageCodes);
  const postageDataInsurance = Object.values(postageCounts.insurance);
  const postageDataRegular = Object.values(postageCounts.regular);

  const chartData =
    chartType === 'insurance' ? postageDataInsurance : postageDataRegular;

  const fetchPostageData = async () => {
    try {
      const response = await axios.post(API_BASE_URL + '/postage_accounting', {
        startDate: dates.startDate,
        endDate: dates.endDate,
        facility_id: facilityId,
      });

      const {
        postageCodesRegular,
        postageCodesInsurance,
        totalPdfCount,
        totalCheckCount,
        insuranceEnvelopeData,
        regularEnvelopeData,
      } = response.data;

      setPostageCounts((data) => ({
        insurance: {
          ...defaultPostageData.insurance,
          ...postageCodesInsurance,
        },
        regular: { ...defaultPostageData.regular, ...postageCodesRegular },
      }));

      setEnvelopeData({
        insurance: {
          ...defaultEnvelopeData,
          ...insuranceEnvelopeData,
        },
        regular: {
          ...defaultEnvelopeData,
          ...regularEnvelopeData,
        },
      });

      setPdfPageCount(totalPdfCount);
      setCheckCount(totalCheckCount);
      setEnvelopeCount(totalCheckCount);
    } catch (error) {
      console.error('Error fetching postage data:', error);
    }
  };

  useEffect(() => {
    fetchPostageData();
  }, [dates, facilityId]);

  const postageBackgroundColors = [
    '#9966FF',
    '#8FAA66',
    '#FF6384',
    '#4BC0C0',
    '#36A2EB',
    '#FF9F40',
    '#FFCD56',
    '#C9CBCF',
    '#FF6384',
    '#36A2EB',
  ];

  const changeDate = (date) => {
    setDates((prevDates) => ({ ...prevDates, startDate: date }));
  };

  const changeEndDate = (date) => {
    setDates((prevDates) => ({ ...prevDates, endDate: date }));
  };

  const handleFacility = (e) => {
    setFacilityId(e.target.value);
  };

  const searchReport = () => {
    fetchPostageData();
  };

  const tooltipCallback = (tooltipItem) => {
    return `${tooltipItem.label}: ${tooltipItem.raw}`;
  };

  const csvPostageReport = {
    data: [
      ...Object.keys(postageCodes).map((key) => [
        postageCodes[key],
        postageCounts.insurance[key],
        postageCounts.regular[key],
      ]),
    ],
    headers: ['Postage Type', 'Insurance Count', 'Regular Count'],
    filename: 'Postage_Report.csv',
  };

  const csvEnvelopeReport = {
    data: [
      ...Object.keys(defaultEnvelopeData).map((key) => [
        key,
        envelopeData.insurance[key],
        envelopeData.regular[key],
      ]),
    ],
    headers: ['Envelope Type', 'Insurance Count', 'Regular Count'],
    filename: 'Envelope_Report.csv',
  };

  const envelopeLabels = Object.keys(defaultEnvelopeData);
  const envelopeDataInsurance = Object.values(envelopeData.insurance);
  const envelopeDataRegular = Object.values(envelopeData.regular);

  const envelopeChartData =
    envelopeChartType === 'insurance'
      ? envelopeDataInsurance
      : envelopeDataRegular;

  return (
    <div>
      {' '}
      <div className='dash_card'>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className='filterCard'>
              {/* <p className="filter_txt">Filter By:</p> */}
              <div>
                {' '}
                <div className='t-header search-facility-admin'>
                  <div className='t-header-left'>
                    From:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      selected={dates.startDate}
                      onChange={changeDate}
                    />
                  </div>
                  <div className='t-header-left'>
                    To:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      selected={dates.endDate}
                      onChange={changeEndDate}
                    />
                  </div>
                  <div className='t-header-right'>
                    <div>
                      <select
                        id='status'
                        className='full-width-elem'
                        onChange={handleFacility}>
                        <option value={'null'}>Facility</option>
                        {facility &&
                          facility.length > 0 &&
                          facility.map((obj, index) => (
                            <option value={obj.id} key={index}>
                              {obj.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className='search_t_head'>
                    <Button
                      type='button'
                      color='primary'
                      onClick={searchReport}>
                      <img src={search} className='search_ico' />
                    </Button>
                  </div> */}
                </div>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Card>
            <CardHeader color='success' stats icon>
              <CardIcon color='danger'>
                <Icon>picture_as_pdf</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Page Count</p>
              <h3 className={classes.cardTitle}>{pdfPageCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Total PDF Count</div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <Card>
            <CardHeader color='info' stats icon>
              <CardIcon color='info'>
                <Icon>outgoing_mail</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total Envelope Count</p>
              <h3 className={classes.cardTitle}>{envelopeCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Total Envelope Count</div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card className='dashboardCard'>
            <h3 className='chart_heads'>Envelope Data</h3>
            <div style={{ width: '100%', height: '80%', paddingTop: '10%' }}>
              <GenericDoughnutChart
                labels={envelopeLabels}
                data={envelopeChartData}
                backgroundColors={postageBackgroundColors}
                tooltipCallback={tooltipCallback}
              />
            </div>
            <div className='dashboardRadio'>
              <div style={{ fontSize: '12px' }}>
                <input
                  type='radio'
                  value='insurance'
                  onChange={handleEnvelopeChartTypeChange}
                  checked={envelopeChartType === 'insurance'}
                  name='envelopeChartType'
                />{' '}
                Envelope Data Insurance
              </div>{' '}
              <div style={{ fontSize: '12px' }}>
                <input
                  type='radio'
                  value='regular'
                  onChange={handleEnvelopeChartTypeChange}
                  checked={envelopeChartType === 'regular'}
                  name='envelopeChartType'
                />{' '}
                Envelope Data Regular
              </div>
            </div>
            <CSVLink
              {...csvEnvelopeReport}
              style={{ cursor: 'pointer' }}
              className='dashboardBtn'>
              Download Report
            </CSVLink>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Card className='dashboardCard'>
            <h3 className='chart_heads'>Envelope Data</h3>
            {Object.keys(defaultEnvelopeData).map((key, index) => (
              <div key={index}>
                <b>
                  <p>{key}</p>
                </b>
                <p>
                  Insurance: {envelopeData.insurance[key]} | Regular:{' '}
                  {envelopeData.regular[key]}
                </p>
              </div>
            ))}
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card className='dashboardCard'>
            <h3 className='chart_heads'>Postage carriers</h3>
            <div style={{ width: '100%', height: '80%', paddingTop: '10%' }}>
              <GenericDoughnutChart
                labels={postageLabels}
                data={chartData}
                backgroundColors={postageBackgroundColors}
                tooltipCallback={tooltipCallback}
              />
            </div>
            <div className='dashboardRadio'>
              <div style={{ fontSize: '12px' }}>
                <input
                  type='radio'
                  value='insurance'
                  onChange={handleChartTypeChange}
                  checked={chartType === 'insurance'}
                  name='chartType'
                />{' '}
                Postage Data Insurance
              </div>{' '}
              <div style={{ fontSize: '12px' }}>
                <input
                  type='radio'
                  value='regular'
                  onChange={handleChartTypeChange}
                  checked={chartType === 'regular'}
                  name='chartType'
                />{' '}
                Postage Data Regular
              </div>
            </div>
            <CSVLink
              {...csvPostageReport}
              style={{ cursor: 'pointer' }}
              className='dashboardBtn'>
              Download Report
            </CSVLink>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Card className='dashboardCard'>
            <h3 className='chart_heads'>Postage carriers</h3>
            {Object.keys(postageCodes).map((key, index) => (
              <div key={index}>
                <b>
                  <p>{postageCodes[key]}</p>
                </b>
                <p>
                  Insurance: {postageCounts.insurance[key]} | Regular:{' '}
                  {postageCounts.regular[key]}
                </p>
              </div>
            ))}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default PostageAccounting;
